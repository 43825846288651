* {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin: 0;
}

.main-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
}

.header {
  /*background-color: #f8f9fa;*/
  text-align: center;
  display: flex;
  height: 30px;
}

.header h1{
  width: 100%;
  height: 50px;
  text-align: center;
  margin: auto 0;
  color: rgba(0, 0, 0, 0.52);
}


.header img{
  width: 200px;
  z-index: 10;
}

.container {
  display: flex;
  flex: 1;
  height: calc(100% - 100px - 50px);
}

.container-content{
  display: flex;
  flex-direction: column;
}

.menu {
  width: calc(200px);
  background-color: rgb(248 249 250);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu ul {
  list-style-type: none;
}



.menu-screens{
  padding: 0;
  height: 100%;
  overflow-y: auto;
  border-top: 15px solid #f8f9fa;
  border-left: 8px solid #f0f0f0;
}

.menu-logout{
  padding: 0;
  border-left: 8px solid #f0f0f0;
}


.menu ul li {
  margin: 10px 0;
  border-radius: 0 7px 7px 0;
}

.submenu-main{
  background-color: #f0f0f0;
}


.menu button {
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.menu button p{
  margin: auto 0 auto 20px;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  line-height: 1.5;
}

.menu button:hover {
  text-decoration: underline ;
}

.menu-button.inactive {
  color: #d3d3d3; /* Világosszürke */
  cursor: not-allowed;
}

.menu-button.inactive:hover {
  font-weight: unset ;
}

.menu-button.active {
  cursor: pointer;
}

.submenu {
  margin-left: 20px;
  list-style-type: none;
  padding: 0;
}


.content {
  padding: 10px;
  display: flex;
  width: calc(100% - 220px);
}

.footer {
  background-color: #f8f9fa;
  text-align: center;
  height: 50px;
  display: flex;
  font-weight: 400;
  font-size: 13px;
  justify-content: center;
  align-items: center;
}

.container_login{
  margin-top: 20vh;
  width: 420px;
  position: absolute;
  right: calc(50% - 350px);
  border: 1px solid #cfd5ca;
  padding: 20px 45px;
  height: 256px;
  border-radius: 20px;
}

.container_login h2{
  font-size: 25px;
  margin: 0 auto 50px;
  color: rgba(0, 0, 0, 0.52);
  text-align: center;
}

.form-group, .form-group-button{
  display: flex;
  margin: 10px 0;
  justify-content: space-between;
}

.form-group-button{
  justify-content: space-evenly;
  margin: 40px 0 0 0;
}

.form-group label{
  padding: 0 15px 0 0;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.52);
}

.form-group input{
  border: unset;
  border-bottom: 1px solid #cfd5ca;
  font-size: 20px;
}

.form-group-button button{
  /*width: 100px;*/
  width: auto;
  font-size: 20px;
  background-color: white;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.52);
  border: 1px solid #cfd5ca;
}

.alert.alert-danger{
  margin: 20px 0 5px 0;
  color: red;
  text-align: center;
  animation-name: visible;
  animation-fill-mode: forwards;
  animation-duration: 1.5s;
}

/*Screen*/

.screen {
  display: flex;
  justify-content: start;
  flex-direction: column;
  position: absolute;
  height: calc(100% - 92px);
  width: calc(100% - 230px);
  /*max-width: 1800px;*/
}

.filterandaction{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 20px 20px 0;
}

/*Screen*/
/* src/components/DataGrid.css */

/* Általános stílusok */
/*Adatgrid*/

.tableContent{
  position: relative;
  height: calc(100% - 10px);
  /*max-width: 1800px;*/
}



/*Screen*/
/* src/components/header.css */

.screen h1{
  font-size: 25px;
  margin: 0 0 10px 2px;
  color: rgba(0, 0, 0, 0.52);
}

.screen header{
  display: flex;
  justify-content: space-between;
  height: 60px;
  /*margin-left: 40px;*/
}

.screen header button {
  display: block;
  margin: 0 10px 17px;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  font-size: 36px;
  color: #d6d6d6;
  border: 1px solid #0000003d;
  background-color: white;
  font-weight: 600;
}

.MuiBox-root css-10gei56{
  z-index: 0;
}

/*helper*/

.helper-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(128, 128, 128, 0.6);
  overflow: auto;
}

.selected-row {
  background-color: lightblue;
}

.helper-modal-content{
  position: absolute;
  padding: 3px;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50%;
  height: fit-content;
  max-height: 90%;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 1)
}

.helper-header{
  display: flex;
  justify-content: space-between;
}

.helper-header button{
  display: block;
  margin: 2px 2px;
  /* height: 45px; */
  /* width: 45px; */
  border-radius: 50%;
  padding: 3px 14px;
  font-size: 33px;
  /* color: #d6d6d6; */
  color: #2d2d2d;
  border: none;
  background-color: white;
  font-weight: 100;
}

.helper-header button:hover{
  background-color: #0000000a;
}


.helper-header h2{
  padding: 10px 14px;
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: #666666;
}

.helper-text-data-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px 15px 10px 15px;
}

.helper-text-field ,.helper-dropdown-field, .helper-text-field_datetime, .helper-text-field_date, .helper-text-field_time{
  flex-direction: column;
  display: flex;
  width: 30%;
  margin: 10px 0;
}

 .css-pwcg7p-MuiCollapse-root {
   width: 100%;
 }

.helper-text-field_num{
  flex-direction: column;
  display: flex;
  width: 15.5%;
  margin: 10px 0;
}

.helper-dropdown, .helper-dropdown input{
  width: 100%;
}

.helper-dropdown input{
  border: unset;
  border-bottom: 1px solid #cfd5ca;
  padding: 5px;
  font-size: 14px;
}

.helper-dropdown-list{
  position: absolute;
  width: 28%;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  z-index: 1000;
  margin-top: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  padding: 2px;
  list-style-type: none;
}

.helper-dropdown-list li{
  padding: 3px 0 3px 2px;
}

.helper-dropdown-list li:hover{
  background-color: #f0f0f0;
}

.helper-text-field label,.helper-textarea-field label, .helper-dropdown-field label, .helper-text-field_num label, .helper-text-field_datetime label, .helper-text-field_date label, .helper-text-field_time label{
  padding: 5px 0 10px 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.52);
}

.helper-text-field textarea,.helper-text-field_num input, .helper-text-field_datetime input, .helper-text-field_date input, .helper-text-field_time input, .helper-dropdown input{
  font-size: 16px;
  width: calc(100% - 5px);
  resize: none;
  overflow: hidden;
  height: 14px;
  border: unset;
  border-bottom: 1px solid #cfd5ca;
  padding: 5px 0 8px 0;
}

.helper-text-field_num input{
  text-align: center;
  padding: 5px 0 8px 0;
}

.helper-text-field textarea:focus-visible,.helper-dropdown input:focus-visible,.helper-text-field_num input:focus-visible,.helper-text-field_datetime input:focus-visible, .helper-text-field_date input:focus-visible, .helper-text-field_time input:focus-visible, .form-group input:focus-visible{
  outline: none;
  border-bottom: 1px solid black;
}

.helper-textarea-data-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}


.helper-textarea-field{
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}

.helper-modal-content form {
  overflow: auto;
}

.helper-textarea-field textarea{
  resize: vertical;
  border-radius: 5px;
  padding: 5px;
  border-color: #cfd5ca;
  border-width: 1px;
}

.helper-textarea-field textarea:focus-visible{
  outline: none;
  border: 1px solid black;
}

.helper-footer-boot{
  display: flex;
  justify-content: end;
  width: 100%;
}

.MuiCollapse-root {
  padding: 5px !important;
}

.helper-footer-boot button{
  display: block;
  margin: 10px 10px 10px;
  height: 33px;
  padding: 0 14px;
  border-radius: 13%;
  font-size: 19px;
  background-color: white;
  border: unset;
  color: #5b5b5b;
}

.helper-footer-boot button:hover{
  background-color: #0000000a;
}

.helper-boolean-field{
  display: flex;
}

.helper-text-boolean-container{
  display: flex;
  justify-content: space-around;
  align-content: center;
  width: 90%;
  margin: 0 auto;
  padding: 10px 0;
}


.helper-boolean-field label{
  margin: auto 7px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.52);
}

.helper-boolean-field p{
  height: 24px;
}

.boolean-button-true,.boolean-button-false,.boolean-button-null{
  color: black;
  font-size: 19px;
  width: 30px;
  margin: auto;
  height: 30px;
  padding: 0;
  border-radius: 10px;
}

.boolean-button-false {
  background-color: #f19b9b;
  border-color: #d74747;
}

.boolean-button-true {
  background-color: #bcffbc;
  border-color: #4aa84a;
}

.dataGrid-detail-textLabel{
  font-size: 18px;
}


.dataGrid-detail-textvalue{
  font-size: 16px;
}

/*Alert message*/
.alert-modal, .confirm-modal{
  background-color: rgba(128, 128, 128, 0.6);
  position: fixed;
  width: 100%;
  z-index: 11;
  height: 100%;
}


.alert-modal-content,.confirm-modal-content{
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 20% auto 0 auto;
  width: 35%;
  height: fit-content;
  background-color: lightgray;
  border: solid 1px;
}

.alert-modal-content-data, .confirm-modal-content-data{
  padding: 8px 8px 0 8px;
}

.alert-modal-content-data, .confirm-modal-content-data{
  padding: 32px 8px 8px 8px;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  align-items: center;
  justify-content: space-between;
}

.alert-modal .helper-header{
  background-color: #f16868;
}

.alert-modal-button {
  display: flex;
  justify-content: center;
  width: 100%;
}

.alert-modal-button button{
  display: block;
  margin: 10px 10px 10px;
  height: 33px;
  padding: 0 14px;
  border-radius: 13%;
  font-size: 19px;
  background-color: white;
  border: unset;
  color: #5b5b5b;
}

.alert-modal-content-data p,.alert-modal-content-data label{
  font-size: 18px;
}

.alert-modal-content-data input{
  margin: 13px 0;
  font-size: 18px;
  width: 50%;
}

.alert-modal .helper-header{
  background-color: #a8b9f1;
}

.alert-modal h2 {
  margin: 0;
}

/* Dropdown.css */
.dropdown {
  position: relative;
  width: 200px;
}

.dropdown input {
  width: 100%;
  box-sizing: border-box;
}


.dropdown-list {
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  z-index: 1000;
  margin-top: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
  list-style-type: none;
}

.dropdown-list li {
  padding: 4px;
  cursor: pointer;
  font-size: 14px;
}

.dropdown-list li:hover {
  background-color: #f0f0f0;
}

.required-field {
  border: 2px solid red;
}

/*Muitable*/

.css-10gei56{
  z-index: 0;
  visibility: hidden;
}

/*Screen*/
/* src/components/footer*/

.screen footer{
  display: flex;
  flex-direction: row;
  height: 22px;
  align-items: center;
}


@keyframes visible {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}